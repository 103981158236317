import React, { useEffect, useState } from "react";
import "../styles/ContactUs.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import SubscribeButton from "../CustomeButton/SubscribeButton";
import Swal from 'sweetalert2';
import { BASE_URL } from "../services/api"; // Ensure this is correctly pointing to your backend

function ContactForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        const formData = {
            name,
            email,
            phoneNumber,
            message,
        };

        try {
            const response = await fetch(`${BASE_URL}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                Swal.fire('Success', 'Your message has been sent!', 'success');
                // Clear form fields
                setName("");
                setEmail("");
                setPhoneNumber("");
                setMessage("");
            } else {
                Swal.fire('Error', 'Failed to send your message. Please try again later.', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An unexpected error occurred. Please try again later.', 'error');
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    };

    return (
        <div className="contact-us">
            <div className="con" data-aos="fade-up">
                <div className="contentss">
                    <div className="address k">
                        <h3><FaMapMarkerAlt color="red" /></h3>
                        <h3 id="contact-headers">Address</h3>
                        <span className="gradient-text">Address: Addis Ababa Bulgariya</span>
                    </div>
                    <div className="phone-number k ks">
                        <h3><FaPhoneAlt /></h3>
                        <h3 id="contact-headers">Phone Number</h3>
                        <p>For Spa and Gym</p>
                        <span className="gradient-text">+2519 66 22 22 28 | +2519 66 22 22 29</span>
                        <p>For other or general</p>
                        <span className="gradient-text">+2519 22 02 22 20 | +2519 11 65 92 95</span>
                    </div>
                    <div className="email k ks">
                        <h3><FaEnvelope /></h3>
                        <h3 id="contact-headers">Email</h3>
                        <span className="gradient-text">dreampower@gmail.com</span>
                    </div>
                </div>
                <div className="inputss">
                    <form onSubmit={handleSubmit}>
                        <div className="upper">
                            <input
                                name="name"
                                type="text"
                                className="inp inpu j"
                                placeholder="Your Name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                            />
                            <input
                                name="phonenumber"
                                type="text"
                                className="inp inpu"
                                placeholder="Your Phone Number"
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="lower">
                            <input
                                name="email"
                                type="email"
                                className="inp inp-low"
                                placeholder="Your Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                            <textarea
                                name="message"
                                placeholder="Message"
                                className="message"
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                required
                            />
                        </div>

                        <div className="buttons buttons-Send">
                            {
                                isSubmitting ? <button className="Send-button" disabled={true}> Sending ...</button> :
                                    (<button type="submit" className="Send-button">
                                        <SubscribeButton className="Send-button" btn={false} />
                                    </button>)
                            }

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
