import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import NavigationMenu from './NavigationMenu';
import '../styles/Header.css';
import logo from "../assets/logo/logo.png";


const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>My Dream Apartments - Luxury Living and World-Class Amenities</title>
                <meta
                    name="description"
                    content="Discover luxury apartments, state-of-the-art fitness facilities, premium office spaces, and exquisite dining options with My Dream Real-Estate in Addis Ababa."
                />
                <meta name="keywords" content="real estate, apartments, gym, spa, office spaces, dining, luxury living, Addis Ababa" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <header className={`navbar-wrapper ${isScrolled ? 'scrolled' : ''}`}>
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center ">
                        <NavigationMenu />
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
