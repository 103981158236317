import React, { useEffect } from "react";
import "../styles/Service.css";
import Aos from "aos";
import { Link } from "react-router-dom";

const Service = ({ id, image, description, title }) => {
    useEffect(() => {
        Aos.init({
            duration: 1000,
        });
    }, []);

    return (
        <div
            className="service"
            data-aos="fade-up"
            id="service-up"
            loading="lazy"
        >
            <img src={image} alt={title} className="service-image" />
            <div className="service-content">
                <h3>{title}</h3>
                <p className="">
                    {description.slice(0, 200)} <b style={{ color: "blue" }}>...</b>
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={`/blog-detail/${id}`} className="blog-link">
                        Explore More
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Service;
