import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../services/api';
import './Blog.css';
import './BlogDetail.css';
import './DetailOnly.css';
import { FaBackward, FaEye } from 'react-icons/fa';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { format, parseISO } from 'date-fns';
import Modal from '../components/Modal'; // Import the Modal component
import Footer from '../components/Footer';

const BlogDetail = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [allBlogs, setAllBlogs] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [selectedBedroom, setSelectedBedroom] = useState(null);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentBlogImages, setCurrentBlogImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        // Scroll to top when component mounts or id changes
        window.scrollTo(0, 0);

        const fetchBlogAndAllBlogs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/${id}`);
                setBlog(response.data);

                const allResponse = await axios.get(`${BASE_URL}/api/blogs/`);
                setAllBlogs(allResponse.data.filter(blog => blog.category === response.data.category));
                window.scrollTo(0, 0);
            } catch (error) {
                console.error('Error fetching blog details:', error);
            }
        };

        fetchBlogAndAllBlogs();
    }, [id]);

    useEffect(() => {
        if (selectedBedroom !== null) {
            setFilteredBlogs(allBlogs.filter(blog => blog.bedroom === selectedBedroom));
        } else {
            setFilteredBlogs(allBlogs);
        }
    }, [selectedBedroom, allBlogs]);

    const handleBedroomSelect = (bedroom) => {
        setSelectedBedroom(bedroom);
    };

    const openGallery = (index, images) => {
        setCurrentImageIndex(index);
        setCurrentBlogImages(images);
        setGalleryOpen(true);
    };

    const closeGallery = (event) => {
        if (event.target === event.currentTarget) {
            setGalleryOpen(false);
        }
    };

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
    };

    const images = blog ? blog.images.map(image => ({ original: image, thumbnail: image })) : [];

    if (!blog) return <p>Loading...</p>;

    const noBlogsMessage = `Currently no apartments are found with ${selectedBedroom || 'any'} Bedroom.`;

    return (
        <>
            <div id="blog-detail">
                <div className="">
                    <Link to="/blog-list">
                        <span className="back blog-title" style={{ color: "grey", marginTop: "35px", fontSize: "1.1rem" }}>Back</span>
                    </Link>
                </div>

                {blog.bedroom && (
                    <div className="bedroom-buttons">
                        {[1, 2, 3].map((bedroom) => (
                            <button
                                key={bedroom}
                                className={`bedroom-button ${selectedBedroom === bedroom ? 'active' : ''}`}
                                onClick={() => handleBedroomSelect(bedroom)}
                            >
                                {bedroom} Bedroom
                            </button>
                        ))}
                        <button
                            className={`bedroom-button ${selectedBedroom === null ? 'active' : ''}`}
                            onClick={() => handleBedroomSelect(null)}
                        >
                            All
                        </button>
                    </div>
                )}

                <div className="blogs-list">
                    {filteredBlogs.length > 0 ? (
                        filteredBlogs.map((filteredBlog, index) => (
                            <div key={index} className="blog-cardss detail-detail-blog">
                                <div className="blog-image-container">
                                    {filteredBlog.images.slice(0, 1).map((image, i) => (
                                        <div key={i} className="image-wrapper">
                                            <img
                                                src={`${image}`}
                                                alt={filteredBlog.title}
                                                className="blog-img"
                                                onClick={() => openGallery(i, filteredBlog.images)}
                                            />
                                            <div className="view-icon" onClick={() => openGallery(i, filteredBlog.images)}>
                                                <FaEye />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="blog-infos">
                                    <span className="blog-title" id='title' style={{ marginLeft: "20px" }}>{filteredBlog.title}</span>
                                    <p className="blog-categories" style={{ marginLeft: "20px" }}>
                                        <strong>Date:</strong>
                                        {filteredBlog.dateAdded ? format(parseISO(filteredBlog.dateAdded), 'MMMM dd, yyyy') : 'No date available'}
                                    </p>
                                    <p className="blog-categories" style={{ marginLeft: "20px" }}>
                                        <strong>Categories:</strong> {filteredBlog.category}
                                    </p>

                                    <p className="blog-categories" style={{ marginLeft: "20px" }}>
                                        <strong>{filteredBlog.bedroom ? `Number Of Bed: ${filteredBlog.bedroom}` : ''}</strong>
                                    </p>
                                    <p className="blog-description">
                                        <span className="preserve-format">{filteredBlog.description.slice(0, 200)} <b>...</b>
                                            <span onClick={() => openModal(filteredBlog.description)} className='span-detail'>Read All</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="no-apartment-message">{noBlogsMessage}</p>
                    )}
                </div>

                {galleryOpen && (
                    <div className="gallery-overlay" onClick={closeGallery}>
                        <Gallery
                            items={currentBlogImages.map(image => ({ original: image, thumbnail: image }))}
                            startIndex={currentImageIndex}
                            onClick={e => e.stopPropagation()} // Prevent closing on gallery click
                        />
                    </div>
                )}

                <Modal isOpen={isModalOpen} onClose={closeModal} content={modalContent} />
            </div>
            <Footer />
        </>
    );
};

export default BlogDetail;
