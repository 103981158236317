import React, { useState, useEffect } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import "../styles/NavigationMenu.css";
import logo from "../assets/logo/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NavigationMenu = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [scrollHeader, setScrollHeader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleToggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleScroll = () => {
        const scrollY = window.pageYOffset;

        if (scrollY >= 80) {
            setScrollHeader(true);
        } else {
            setScrollHeader(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleLinkClick = (sectionId) => {
        // Close the menu
        setShowMenu(false);

        // Check if already on the homepage
        if (location.pathname !== '/') {
            // Navigate to the home page first
            navigate('/', { replace: true });
        }

        // Use a timeout to ensure navigation has occurred before scrolling
        setTimeout(() => {
            // Scroll to the section smoothly
            const element = document.querySelector(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 0);
    };

    return (
        <header
            className={`header ${scrollHeader ? "scroll-header" : ""}`}
            id="header"
        >
            <nav className="nav container" aria-label="Main Navigation">
                <Link to="/" className="nav__logo" aria-label="Homepage">
                    <img
                        src={logo}
                        alt="My Dream Real-Estate Logo"
                        className="img-fluid logo-logo"
                    />
                    <div className="logo-text">
                        <p className="logo-title">My Dream</p>
                        <span className="logo-subtitle">Apartments</span>
                    </div>
                </Link>

                <div
                    className={`nav__menu ${showMenu ? "show-menu" : ""}`}
                    id="nav-menu"
                >
                    <ul className="nav__list">
                        <li className="nav__item">
                            <a
                                href="#home"
                                className={`nav__link ${location.hash === "#home" ? "active-link" : ""}`}
                                onClick={() => handleLinkClick("#home")}
                                aria-label="Navigate to Home section"
                            >
                                <span id={`${scrollHeader ? "color-navlist" : ""}`}>
                                    Home
                                </span>
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#services"
                                className={`nav__link ${location.hash === "#services" ? "active-link" : ""}`}
                                onClick={() => handleLinkClick("#services")}
                                aria-label="Navigate to Services section"
                            >
                                <span id={`${scrollHeader ? "color-navlist" : ""}`}>
                                    Services
                                </span>
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#blog"
                                className={`nav__link ${location.hash === "#blog" ? "active-link" : ""}`}
                                onClick={() => handleLinkClick("#blog")}
                                aria-label="Navigate to Blog section"
                            >
                                <span id={`${scrollHeader ? "color-navlist" : ""}`}>
                                    Blog
                                </span>
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#aboutus"
                                className={`nav__link ${location.hash === "#aboutus" ? "active-link" : ""}`}
                                onClick={() => handleLinkClick("#aboutus")}
                                aria-label="Navigate to About Us section"
                            >
                                <span id={`${scrollHeader ? "color-navlist" : ""}`}>
                                    About Us
                                </span>
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#contact"
                                className={`nav__link ${location.hash === "#contact" ? "active-link" : ""}`}
                                onClick={() => handleLinkClick("#contact")}
                                aria-label="Navigate to Contact Us section"
                            >
                                <span id={`${scrollHeader ? "color-navlist" : ""}`}>
                                    Contact Us
                                </span>
                            </a>
                        </li>
                    </ul>

                    {/* Close button */}
                    <div
                        className="nav__close"
                        id="nav-close"
                        onClick={handleToggleMenu}
                        aria-label="Close navigation menu"
                    >
                        <FaTimes />
                    </div>
                </div>

                {/* Toggle button */}
                <div
                    className="nav__toggle"
                    id="nav-toggle"
                    onClick={handleToggleMenu}
                    aria-label="Open navigation menu"
                >
                    <FaBars id={`${scrollHeader ? "color-navlist" : ""}`} />
                </div>
            </nav>
        </header>
    );
};

export default NavigationMenu;
