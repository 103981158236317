import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Services from './Services';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Phone from './Phone';
import { Helmet } from 'react-helmet-async';
import YouTubeVideo from "../Youtube/YoutubeVideo"
import { useState, useEffect } from "react";
import { BASE_URL } from '../services/api'; // Import the API function
import axios from "axios";

const getRandomImage = (images) => {
    if (images.length === 0) return ''; // Handle case with no images
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
};


const Home = () => {
    const [video, setVideo] = useState([])

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;

                const serviceFiltered = allBlogs.filter(blog =>
                    blog.category.includes('video')
                );

                serviceFiltered.map(e => (setVideo(e)))

            } catch (error) {
                // console.error('Error fetching services:', error);
            }
        };


        fetchServices();
    }, []);

    return (
        <div>
            <Helmet>
                <title>MyDream Apartments - Luxury Living in Prime Locations</title>
                <meta
                    name="description"
                    content="Discover MyDream Apartments, offering luxury living in prime locations with modern amenities. Contact us today to find your dream home."
                />
                <meta
                    name="keywords"
                    content="luxury apartments, MyDream Apartments, modern amenities, prime locations, contact us"
                />
                <link rel="canonical" href="https://mydream-apartments.com" />
            </Helmet>

            <Header />
            <HeroSection />
            <Services />
            <AboutUs />
            <YouTubeVideo title={video.title} url={video.description} />
            <ContactUs />
            <Phone />
        </div>
    );
};

export default Home;
