import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Tooltip } from "react-tooltip"
import "../styles/Phone.css"; // Create a separate CSS file for styling

const Phone = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleClick = () => {
        if (isDesktop) {
            // Do nothing since tooltip will show the number
        } else {
            window.open("tel:+251911659295");
        }
    };

    return (
        <div className="phone-icon" onClick={handleClick} data-tip="+2519 11 65 92 95">
            <FaPhoneAlt color="#fff" className="icon-phone-only" />
            {isDesktop && <Tooltip place="left" type="dark" effect="solid" />}
        </div>
    );
};

export default Phone;
