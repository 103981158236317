import React from 'react';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { styled } from '@mui/system';

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
    maxWidth: '90%',
    width: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
    animation: 'fadeIn 0.3s ease-out',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        width: '90%',
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: theme.palette.text.primary,
    transition: 'color 0.3s ease, transform 0.3s ease',
    '&:hover': {
        color: theme.palette.error.main,
        transform: 'scale(1.1)',
    },
}));

const ModalBody = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    color: theme.palette.text.primary,
    lineHeight: '1.6',
    marginTop: "25px"
}));

const ModalComponent = ({ isOpen, onClose, content }) => {
    return (
        <StyledModal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <ModalContent>
                <CloseButton onClick={onClose}>
                    <FaTimes size={20} />
                </CloseButton>
                <ModalBody id="modal-description" component="div" className='preserve-format'>
                    {content}
                </ModalBody>
            </ModalContent>
        </StyledModal>
    );
};

export default ModalComponent;
