import React, { useState, useEffect } from "react";
import "../styles/Testimonials.css"; // Make sure to create this CSS file
import { BASE_URL } from "../services/api";
import axios from "axios";
import Aos from "aos";


const getRandomImage = (images) => {
    if (images.length === 0) return ''; // Handle case with no images
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
};

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);



    useEffect(() => {
        Aos.init({
            duration: 1000,
        });
    }, []);


    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;

                const serviceFiltered = allBlogs.filter(blog =>
                    blog.category.includes('client-says')
                );


                const servicesWithRandomImages = serviceFiltered.map(service => ({
                    ...service,
                    image: getRandomImage(service.images.map(image => `${image}`)) // Adjust URL if necessary
                }));

                setTestimonials(servicesWithRandomImages);

            } catch (error) {
                // console.error('Error fetching services:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTestimonial((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000); // Change testimonial every 5 seconds

        return () => clearInterval(interval);
    }, [testimonials.length]);

    const handleDotClick = (index) => {
        setCurrentTestimonial(index);
    };

    if (loading) return <p className="loading">Loading</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="testimonials-section">
            <p id="header-section" data-aos="fade-right">Testimonials</p>
            <div className="testimonial-wrapper">
                <div
                    className="testimonial-slider"
                    style={{ transform: `translateX(-${currentTestimonial * 100}%)` }}
                >
                    {testimonials.map((testimonial, index) => (

                        <div key={index} className="testimonial" data-aos="fade-left" >
                            <img src={testimonial.images[0]} alt={testimonial.title} className="testimonial-photo" />
                            <div className="testimonial-content">
                                <p className="testimonial-name">{testimonial.title}</p>
                                <p className="team-member-title" id="team-member-title">Customer</p>
                                <p className="testimonial-review preserve-format">"{testimonial.description.slice(0, 600)}"</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="testimonial-dots">
                    {testimonials.map((_, index) => (
                        <div
                            key={index}
                            className={`dot ${currentTestimonial === index ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
