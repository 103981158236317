import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/HeroSection.css";
import SubscribeButton from "../CustomeButton/SubscribeButton";
import Aos from "aos";
import { BASE_URL } from "../services/api"; // Adjust the import based on your project structure

const HeroSection = () => {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        Aos.init({
            duration: 1000,
        });
    }, []);

    useEffect(() => {
        const fetchBackgroundImages = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;
                const backgroundImages = allBlogs.filter(blog => blog.category.includes('background-images'));

                const imageUrls = backgroundImages.flatMap(blog =>
                    blog.images.map(image => `${image}`)
                );

                setImages(imageUrls);
            } catch (error) {
                // console.error('Error fetching background images:', error);
            }
        };

        fetchBackgroundImages();
    }, []);


    useEffect(() => {
        if (images.length === 0) return; // Prevents errors when images are empty

        const intervalId = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [images]);

    return (
        <div className="hero-section" id="home">
            <div className="hero-content" data-aos="fade-up">
                <h1 className="hero-headline">
                    Experience Luxury Living and World-Class Amenities with My Dream Apartments
                </h1>
                <p className="hero-subheadline">
                    Offering premium apartments, office spaces, spa treatments, and dining in Bulgaria, Addis Ababa.
                </p>
                <div className="hero-cta">
                    <a href="#services">
                        <SubscribeButton className="cta-button" btn={true} />
                    </a>
                </div>
            </div>
            <div className="hero-slider">
                <div
                    className="hero-slider-images"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="hero-slider-image"
                            style={{ backgroundImage: `url(${image})` }}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
