import React, { useEffect } from 'react';
import '../styles/MissionVision.css';
import { FaBullhorn, FaGlobeAmericas } from 'react-icons/fa'; // Import icons
import roadBackground from '../assets/logo/road7.jpg'; // Import image
import { initParallax } from '../utils/initParallax';


const MissionVision = () => {
    useEffect(() => {
        initParallax();
    }, []);

    return (
        <section className="mission-vision"
            style={{
                backgroundImage: `url(${roadBackground})`,
            }}
            data-speed="2">
            <span className='mission-span'>
                <div class="text-overlay-container">
                    <p id="mis-vis">Mission & Vision</p>
                </div>
            </span>
            <div className="mission-vision-container">
                <div className="mission" >
                    <FaBullhorn className="icon" />
                    <div className="content">
                        <h3>Mission</h3>
                        <p>Our mission is to deliver exceptional apartmemts services with integrity, professionalism, and a commitment to client satisfaction.</p>
                    </div>
                </div>
                <div className="vision">
                    <FaGlobeAmericas className="icon" />
                    <div className="content">
                        <h3>Vision</h3>
                        <p>To be the leading apartmemts provider in Addis Ababa, known for our innovative solutions and unmatched quality.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionVision;
