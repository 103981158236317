import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import BlogDetail from './Blogs/BlogDetail';
import BlogList from './Blogs/BlogList';
import BlogForm from './component/BlogForm';
import './index.css';

function App() {
  const [blogs, setBlogs] = useState([]);

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/about" element={<PageLayout><AboutUs /></PageLayout>} />
          <Route path="/contact" element={<PageLayout><ContactUs /></PageLayout>} />
          <Route path="/blog-list" element={<PageLayout><BlogList /></PageLayout>} />
          <Route path="/blog-detail/:id" element={<BlogDetailLayout><BlogDetail /></BlogDetailLayout>} />
          <Route path="/" element={<PageLayout><Home /></PageLayout>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

function PageLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

function BlogDetailLayout({ children }) {
  const location = useLocation();

  // Check if the current path matches the BlogDetail path
  const showFooter = location.pathname !== '/blog-detail';

  return (
    <>
      <Header />
      {children}
    </>
  );
}

export default App;
