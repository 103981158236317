import React, { useEffect } from 'react';
import "../styles/AboutUs.css"; // Ensure you create this CSS file
import MissionVision from '../components/MissionVision';
import { FaBriefcase, FaBuilding, FaCalendarAlt, FaMapMarkerAlt, FaTrophy } from 'react-icons/fa';
import TeamMember from '../components/TeamMember';
import Aos from 'aos';
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {

    useEffect(() => {
        Aos.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="about-us" id='aboutus'>
            <Helmet>
                <title>About Us | My Dream Apartments</title>
                <meta
                    name="description"
                    content="Discover the history, mission, and team of My Dream Apartments, a leader in luxury real estate services in Addis Ababa."
                />
                <meta
                    name="keywords"
                    content="apartmemts, luxury apartments, Addis Ababa, Apartments services, My Dream Apartments"
                />
            </Helmet>
            {/* Introduction */}
            <div className="about-page" data-aos="fade-up">
                <section className="about-introduction">
                    <div className="content-wrapper">
                        <h1 className="intro-title">My Dream Apartments</h1>
                        <p className="intro-text">
                            Welcome to My Dream Apartments, where luxury meets convenience. Our mission is to provide unparalleled apartments services, ensuring that every client finds their dream property or service with us.
                        </p>
                    </div>
                    <div className="intro-animation">
                        <div className="floating-bubble bubble1"></div>
                        <div className="floating-bubble bubble2"></div>
                        <div className="floating-bubble bubble3"></div>
                    </div>
                </section>
            </div>

            {/* Company History */}
            <div className="about-page" data-aos="fade-in">
                <section className="company-history">
                    <h2>History & Milestones</h2>
                    <div className="timeline">
                        <div className="timeline-item">
                            <div className="timeline-icon">
                                <FaBuilding />
                            </div>
                            <div className="timeline-content">
                                <h3>Founded in [Year]</h3>
                                <p>
                                    My Dream Apartments started with a vision to redefine real estate experiences in Addis Ababa. Over the years, we have expanded our services to include luxury apartments, modern office spaces, premium spa services, and more.
                                </p>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-icon">
                                <FaBriefcase />
                            </div>
                            <div className="timeline-content">
                                <h3>Expansion</h3>
                                <p>
                                    Successfully expanded to include a wide range of luxury properties and services, becoming a leader in the Addis Ababa apartments market.
                                </p>
                            </div>
                        </div>
                        <div className="timeline-item">
                            <div className="timeline-icon">
                                <FaTrophy />
                            </div>
                            <div className="timeline-content">
                                <h3>Awards & Recognitions</h3>
                                <p>
                                    Recognized as the "Best apartments Company in Addis Ababa 2023" and received the "Excellence in Customer Service Award 2022".
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Mission and Vision */}
            <section>
                <MissionVision />
            </section>

            {/* Team Members */}
            <section>
                <TeamMember />
            </section>
            {/* Call to Action */}
            <section id="contact" style={{ paddingBottom: "90px" }}></section>

        </div>
    );
};

export default AboutUs;
