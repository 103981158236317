import React, { useState, useEffect } from "react";
import "../styles/TeamMember.css"; // Ensure this file is updated with the new styles
import { BASE_URL, getBlogs } from '../services/api'; // Import the API function
import axios from "axios";


const getRandomImage = (images) => {
    if (images.length === 0) return ''; // Handle case with no images
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
};

const TeamMember = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [currentTeamMember, setCurrentTeamMember] = useState(0);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;

                const serviceFiltered = allBlogs.filter(blog =>
                    blog.category.includes('team-info')
                );


                const servicesWithRandomImages = serviceFiltered.map(service => ({
                    ...service,
                    image: getRandomImage(service.images.map(image => `${image}`)) // Adjust URL if necessary
                }));

                setTeamMembers(servicesWithRandomImages);

            } catch (error) {
                // console.error('Error fetching services:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);




    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTeamMember(prevIndex => (prevIndex + 1) % teamMembers.length);
        }, 4000); // Change team member every 4 seconds

        return () => clearInterval(interval);
    }, [teamMembers]);

    const handleDotClick = (index) => {
        setCurrentTeamMember(index);
    };

    return (
        <div className="team-section">
            <p id="header-section">Meet Our Team</p>
            <div className="team-wrapper">
                <div
                    className="team-slider"
                    style={{ transform: `translateX(-${currentTeamMember * 100}%)` }}
                >
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-member">
                            <img
                                src={`${member.images[0]}`}
                                alt={member.name}
                                className="team-member-photo"
                            />
                            <div className="team-member-info">
                                <h3>{member.title}</h3>
                                <p className="team-member-title">Team</p>
                                <p className="team-member-bio preserve-format">{member.description.slice(0, 600)}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="team-dots">
                    {teamMembers.map((_, index) => (
                        <div
                            key={index}
                            className={`dot ${currentTeamMember === index ? "active" : ""}`}
                            onClick={() => handleDotClick(index)}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeamMember;
