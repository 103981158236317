// src/index.js

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your theme
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import AnimatedLoader from "./AnimatedLoader/AnimatedLoader";
import YouTubeVideo from './Youtube/YoutubeVideo';

const RootComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3500); // 3.5 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  return loading ? <AnimatedLoader /> : <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RootComponent />
    </ThemeProvider>
  </React.StrictMode>
);
