import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

// Styled component for the video container
const PlayerWrapper = styled.div`
  position: relative;
  margin: 20px auto;
  width: 70%;
  max-width: 800px;
  border-radius: 12px;
  overflow: hidden;
  background: grey;
  color:white;
  display:flex;
  justify-content:center;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const TikTokIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Make iframe height fill the container */
  border: none;
`;

const InstagramIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const FacebookIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  @media (max-width: 768px) {
    height: 400px;
  }
`;

const NoContent = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  background: grey;
  border-radius: 12px;
`;

const VideoPlayer = ({ url, title }) => {
    if (!url) {
        // If no URL is provided, render a placeholder message
        return (
            <PlayerWrapper>
                <NoContent></NoContent>
            </PlayerWrapper>
        );
    }

    const isYouTube = url.includes('youtube.com') || url.includes('youtu.be');
    const isTikTok = url.includes('tiktok.com');
    const isVimeo = url.includes('vimeo.com');
    const isInstagram = url.includes('instagram.com');
    const isFacebook = url.includes('facebook.com');

    // Determine container height based on video type
    const containerHeight = isYouTube ? '400px'
        : isTikTok ? '760px'
            : isVimeo ? '400px'
                : isInstagram ? '700px'
                    : isFacebook ? '450px'
                        : 'auto';

    // Extract TikTok video ID from the URL
    const getTikTokEmbedUrl = (url) => {
        const videoId = url.split('/').pop().split('?')[0];
        return `https://www.tiktok.com/embed/${videoId}`;
    };

    // Extract Vimeo video ID from the URL
    const getVimeoEmbedUrl = (url) => {
        const videoId = url.split('/').pop();
        return `https://player.vimeo.com/video/${videoId}`;
    };

    // Get Instagram Embed URL
    const getInstagramEmbedUrl = (url) => {
        const shortcode = url.split('/')[4];
        return `https://www.instagram.com/p/${shortcode}/embed`;
    };

    // Get Facebook Embed URL
    const getFacebookEmbedUrl = (url) => {
        const postId = url.split('/').pop();
        return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}`;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ display: "flex", justifyContent: "center", fontWeight: "600", fontSize: "1.4rem", color: "#0459b4" }}>{title}</p>
            <PlayerWrapper style={{ height: containerHeight }}>
                {isYouTube ? (
                    <ReactPlayer
                        url={url}
                        width="100%"
                        height="100%"
                        controls={true} // Show default YouTube controls
                    />
                ) : isTikTok ? (
                    <TikTokIframe
                        src={getTikTokEmbedUrl(url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="TikTok Video"
                    ></TikTokIframe>
                ) : isVimeo ? (
                    <iframe
                        src={getVimeoEmbedUrl(url)}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Vimeo Video"
                    ></iframe>
                ) : isInstagram ? (
                    <InstagramIframe
                        src={getInstagramEmbedUrl(url)}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                        allowtransparency="true"
                        allow="encrypted-media"
                        title="Instagram Video"
                    ></InstagramIframe>
                ) : isFacebook ? (
                    <FacebookIframe
                        src={getFacebookEmbedUrl(url)}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                        allow="encrypted-media"
                        title="Facebook Video"
                    ></FacebookIframe>
                ) : (
                    <p>Unsupported video URL</p>
                )}
            </PlayerWrapper>
        </div>
    );
};

export default VideoPlayer;
