// src/components/Map.js

import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

// Your Google Maps API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyCcT04NmUG9c8yT0OqUnj36Q5IcLpuoYhY';

// Your company's location (latitude and longitude)
const position = { lat: 8.996963, lng: 38.749678 };

// Custom red icon URL
const redIcon = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";

const Map = () => {
    const [selected, setSelected] = useState(null);
    const mapRef = useRef(null); // Create a ref for the map

    useEffect(() => {
        if (mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(position);
            mapRef.current.fitBounds(bounds);
        }
    }, [mapRef]);

    return (
        <div style={{ height: "50vh", width: "100%" }}>
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                    mapContainerStyle={{ height: "100%", width: "100%" }}
                    center={position}
                    zoom={17.95} // Initial zoom level
                    onLoad={(mapInstance) => {
                        mapRef.current = mapInstance; // Save the map instance
                    }}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: true
                    }}
                >
                    <Marker
                        position={position}
                        icon={redIcon} // Use the custom red icon
                        onClick={() => setSelected(position)} // Set marker as selected on click
                    />
                    {selected ? (
                        <InfoWindow
                            position={selected}
                            onCloseClick={() => setSelected(null)} // Deselect marker when closing
                        >

                        </InfoWindow>
                    ) : null}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default Map;
