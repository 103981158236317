import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/BlogHighlight.css';
import axios from 'axios';
import { BASE_URL } from '../services/api';
import { Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns'; // Import functions from date-fns

const BlogHighlights = () => {
    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            delay: 200,
        });
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;

                // Filter blogs with 'blog-latest' category
                const blogLatest = allBlogs.filter(blog =>
                    blog.category.includes('blog-latest')
                );

                // Sort blogs by date in descending order (newest first)
                const sortedBlogs = blogLatest.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Select the top 3 most recent blogs
                const topThreeBlogs = sortedBlogs.slice(0, 3);

                setBlogPosts(topThreeBlogs);
            } catch (error) {
                // console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div className="blog-highlights-section" id="blog">
            <h2 style={{ color: "grey" }}>Latest Blog Posts</h2>
            <div className="blog-grid">
                {blogPosts.map((post, index) => (
                    <div
                        key={post._id} // Use post._id for key
                        className="blog-post"
                        data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}
                        data-aos-delay={index * 200}
                    >
                        <img
                            src={`${post.images[0]}`}
                            alt={post.title}
                            className="blog-thumbnail"
                        />
                        <div className="blog-content">
                            <p className="blog-title" id='blog-title'>{post.title}</p>
                            <p className="blog-excerpt">{post.description.slice(0, 200)} <b style={{ color: "blue" }}>...</b></p>
                            <span className="blog-date">
                                {post.dateAdded ? format(parseISO(post.dateAdded), 'MMMM dd, yyyy') : 'No date available'}
                            </span>
                            <Link to={`/blog-detail/${post._id}`} className="blog-link">Read More</Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogHighlights;
