import React from 'react';
import '../styles/ContactUs.css'; // Custom CSS styles for ContactUs
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';

const ContactUs = () => {
    return (
        <section className="contact-us-container" data-target="contact">
            <div className="contact-us-content">
                <p id='header-section' style={{ display: "flex", justifyContent: "center" }}>Contact Us</p>
                <section className="cta">
                    <p id='cta-h2' >Ready to Find Your Dream Property?</p >
                    <p>Contact us today to get started!</p>
                    <a href="/contact" className="cta-button">Contact Us</a>
                </section>
                <div className="map-width map-container">
                    <Map />
                </div>
                <div className="contact-form-section">
                    <ContactForm />
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
