import React, { useEffect, useState } from "react";
import Service from "../components/Service";
import "../styles/Service.css";
import Testimonials from "../components/Testimonials";
import BlogHighlights from "../components/BlogHighlight";
import Aos from "aos";
import { BASE_URL } from "../services/api";
import axios from "axios";
import { Helmet } from "react-helmet-async";

// Helper function to select a random image
const getRandomImage = (images) => {
    if (images.length === 0) return ''; // Handle case with no images
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
};

const Services = () => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        Aos.init({
            duration: 1000,
        });
    }, []);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;

                const serviceFiltered = allBlogs.filter(blog =>
                    !blog.category.includes('background-images') &&
                    !blog.category.includes('password') &&
                    !blog.category.includes('blog-latest') &&
                    !blog.category.includes('client-says') &&
                    !blog.category.includes('team-info') &&
                    !blog.category.includes('video')
                );

                // Group blogs by category
                const categoryMap = new Map();

                serviceFiltered.forEach(blog => {
                    if (!categoryMap.has(blog.category)) {
                        categoryMap.set(blog.category, blog);
                    }
                });

                // Convert Map values to an array and map services to select a random image if multiple images are available
                const servicesWithRandomImages = Array.from(categoryMap.values()).map(service => ({
                    ...service,
                    image: getRandomImage(service.images.map(image => `${image}`)) // Adjust URL if necessary
                }));

                setServices(servicesWithRandomImages);
            } catch (error) {
                // console.error('Error fetching services:', error);
            }
        };


        fetchServices();
    }, []);

    return (
        <div className="service-upper" id="services">

            <Helmet>
                <title>About Us | My Dream Apartments</title>
                <meta
                    name="description"
                    content="Discover the history, mission, and team of My Dream Real-Estate, a leader in luxury real estate services in Addis Ababa."
                />
                <meta
                    name="keywords"
                    content="apartments, luxury apartments, Addis Ababa, gym , spa ,restaurant ... services, My Dream apartments"
                />
            </Helmet>
            <section className="introduction-text" id="service-upper">
                <div className="intro-content" data-aos="fade-in">
                    <p id="intro-content-title">Welcome to My Dream Apartments</p>
                    <p>
                        At My Dream Apartments, we offer a comprehensive suite of services to cater to all your lifestyle and business needs. Discover luxury living, state-of-the-art fitness facilities, premium office spaces, and exquisite dining options, all in one place.
                    </p>
                </div>
            </section>
            <div className="services">
                {services.map((service, index) => (
                    <Service
                        key={index}
                        id={service._id}
                        image={service.image} // Use the selected random image
                        title={service.title}
                        description={service.description}
                        delay={index * 100}
                    />
                ))}
            </div>
            <Testimonials />
            <section id="blog">
                <BlogHighlights />
            </section>
        </div>
    );
};

export default Services;
