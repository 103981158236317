// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#003366', // Base color for primary
        },
        secondary: {
            main: '#ff5722', // Secondary color for accents
        },
        background: {
            default: '#f0f0f0', // Background color for the app
        },
        text: {
            primary: '#333333', // Main text color
            secondary: '#666666', // Secondary text color
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: '2rem',
            fontWeight: 700,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        // Add more typography styles as needed
    },
    components: {
        // Customize MUI components if needed
    },
});

export default theme;
