import React, { useEffect } from "react";
import "../styles/Footer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdOutlineEmail } from "react-icons/md";
import { FaTelegram, FaGreaterThan, FaLessThan, FaArrowLeft } from "react-icons/fa";
import {
    FaHome,
    FaConciergeBell,
    FaRegNewspaper,
    FaUserAlt,
    FaPhoneAlt,
} from 'react-icons/fa';
import SocialMediaFooter from "./SocialMediaFooter";
import { FaAnglesLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Footer() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const location = useLocation();
    const navigate = useNavigate();


    const handleLinkClick = (sectionId) => {

        // Check if already on the homepage
        if (location.pathname !== '/') {
            // Navigate to the home page first
            navigate('/', { replace: true });
        }

        // Use a timeout to ensure navigation has occurred before scrolling
        setTimeout(() => {
            // Scroll to the section smoothly
            const element = document.querySelector(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 0);
    };

    return (
        <div>
            <div className="footer" data-aos="fade" id="footer">
                <div className="class">
                    <p id="footer-header">My Dream Apartments</p >
                    <p><b>Address:</b> <pre>Addis Ababa , Kera Bulgariya | ቄራ ቡልጋርያ</pre></p>

                    <br />
                    <p>


                        <b>Phone:</b> <br></br>+2519 22 02 22 20, <br />
                        +2519 11 65 92 95
                    </p>
                    <p>
                        <b>Email:</b> dreampower@gmail.com
                    </p>
                </div>
                <div className="class classlist useful-link">
                    <p id="footer-useful-link">Useful Links</p>
                    <ul>
                        <li><FaHome color="orange" size={12} style={{ marginRight: "5px" }} /> <Link to="#home" onClick={() => handleLinkClick("#home")}>Home</Link></li>
                        <li><FaConciergeBell color="orange" size={12} style={{ marginRight: "5px" }} /> <Link to="#services" onClick={() => handleLinkClick("#services")}>Services</Link></li>
                        <li><FaRegNewspaper color="orange" size={12} style={{ marginRight: "5px" }} /> <Link to="#blog" onClick={() => handleLinkClick("#blog")} >Blog</Link></li>
                        <li><FaUserAlt color="orange" size={12} style={{ marginRight: "5px" }} /> <Link to="#aboutus" onClick={() => handleLinkClick("#aboutus")}>About us</Link></li>
                        <li><FaPhoneAlt color="orange" size={12} style={{ marginRight: "5px" }} /> <Link to="#contact" onClick={() => handleLinkClick("#contact")}>Contact</Link></li>
                    </ul>
                </div>

                <div className="social-media-content">
                    <p id="footer-useful-link">Visit Us</p>
                    <div className="icons-social">
                        <SocialMediaFooter />
                    </div>
                </div>
            </div>
            <div className="last-footer">
                <p style={{ color: "grey" }}>Dream Apartments | Copyright (R) 2024 | Web Developed by : <a href="https://clickaddis.web.app" style={{ color: "grey" }} target="__blank"><b> Click Addis</b></a> </p>
            </div>
        </div>
    );
}

export default Footer;
