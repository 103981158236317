import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BASE_URL } from '../services/api';
import './Blog.css';
import { FaSearch, FaTag } from 'react-icons/fa';
import axios from 'axios';

const getRandomImage = (images) => {
    if (!images || images.length === 0) return ''; // Handle case with no images
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
};

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [categories, setCategories] = useState([
        'All', 'spa', 'gym', 'office', 'apartment-sales', 'apartment-rent', 'restaurant', 'bank', 'pharmacy', 'supermarket', 'blog-latest'
    ]);

    const [isSearchVisible, setSearchVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/blogs/`);
                const allBlogs = response.data;
                const filteredBlogs = allBlogs.filter(blog =>
                    !blog.category.includes('background-images') &&
                    !blog.category.includes('password') &&
                    !blog.category.includes('client-says') &&
                    !blog.category.includes('team-info') &&
                    !blog.category.includes('video')
                );

                setBlogs(filteredBlogs);
            } catch (error) {
                // console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);



    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const category = queryParams.get('category');
        if (category) {
            setSelectedCategory(category);
        }
    }, [location.search]);

    const filteredBlogs = blogs.filter(blog => {
        // Check if `category` is an array or a string
        const blogCategories = Array.isArray(blog.category)
            ? blog.category.map(category => category.toLowerCase())
            : [blog.category.toLowerCase()]; // Handle single string case

        // Ensure selectedCategory is lowercase for case-insensitive comparison
        const selectedCategoryLower = selectedCategory.toLowerCase();

        // Check if blog matches search query
        const matchesSearchQuery = (
            (blog.title && blog.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (blog.description && blog.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (blogCategories.join(' ').toLowerCase().includes(searchQuery.toLowerCase())) ||
            (blog.date && blog.date.toLowerCase().includes(searchQuery.toLowerCase()))
        );

        // Check if blog matches selected category
        const matchesCategory = selectedCategoryLower === 'all' ||
            blogCategories.includes(selectedCategoryLower);

        return matchesSearchQuery && matchesCategory;
    });

    return (
        <div className="blog-list" id='blog-list'>
            <div className="filters">
                <div className="search-container">
                    <FaSearch
                        className="search-icon"
                        onClick={() => setSearchVisible(!isSearchVisible)}
                    />
                    <div className={`search-input-container ${isSearchVisible ? 'visible' : ''}`}>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="search-bar"
                            id='search-bar'
                        />
                    </div>
                </div>
                <div className="category-container">
                    <FaTag className="category-icon" />
                    <select
                        value={selectedCategory}
                        onChange={e => setSelectedCategory(e.target.value)}
                        className="category-select"
                    >
                        {categories.map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className={`${filteredBlogs.length > 0 ? "blog-cards" : "blog-card-no-result"}`}>
                {filteredBlogs.length > 0 ? (
                    filteredBlogs.map(blog => (
                        <div key={blog._id} className="blog-card">
                            <img
                                src={getRandomImage(blog.images)} // Use the random image function
                                alt={blog.title}
                                className="blog-image"
                            />
                            <div className="blog-content">
                                <h2>{blog.title}</h2>
                                <p className='preserve-format'>{blog.description ? blog.description.slice(0, 100) : ''}...</p>
                                <Link to={`/blog-detail/${blog._id}`} className="read-more">Read More</Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='no-result-container'>
                        <div className="no-results">
                            <p>Nothing is found</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlogList;
