import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTelegram,
  FaTiktok,
} from "react-icons/fa";
import "../styles/SocialMediaFooter.css";

function SocialMediaFooter() {
  return (
    <div className="footer">
      <div className="social-icons">
        <a
          href="https://www.facebook.com/share/p/HRJqCnrcGM34Mq5v/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon facebook"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          className="icon twitter"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          className="icon instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.tiktok.com/@dream_spa_gym"
          target="_blank"
          rel="noopener noreferrer"
          className="icon linkedin"
        >
          <FaTiktok />
        </a>
        <a
          href="https://t.me/mydreamRealestate"
          target="_blank"
          rel="noopener noreferrer"
          className="icon telegram"
        >
          <FaTelegram />
        </a>
      </div>
    </div>
  );
}

export default SocialMediaFooter;
