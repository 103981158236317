// src/utils/parallax.js

export function initParallax() {
    const parallaxElement = document.querySelector('.mission-vision');

    if (!parallaxElement) return;

    function updateParallax() {
        const scrollPosition = window.pageYOffset;
        const parallaxSpeed = parseFloat(parallaxElement.getAttribute('data-speed')) || 0.5;
        const backgroundPosition = scrollPosition * parallaxSpeed;

        parallaxElement.style.backgroundPositionY = `${backgroundPosition}px`;
    }

    window.addEventListener('scroll', updateParallax);
    updateParallax(); // Initial call to set position
}
