import React, { Component } from "react";
import "./SubscribeButton.css";
function SubscribeButton({ btn }) {
  return (
    <div>
      <button class={`${btn ? "Btn" : "Btn2"}`}></button>
    </div>
  );
}

export default SubscribeButton;
